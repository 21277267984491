import HttpRequest from '@/util/HttpRequest';

// Lookup a book metadata by ISBN
export default async function lookupBookByIsbn(isbn) {
  const url = `https://openlibrary.org/api/books?bibkeys=ISBN:${isbn}&jscmd=data&format=json`;

  return new Promise((resolve, reject) => {
    HttpRequest.get(url).then((res) => {
      const book = {};
      let isbn13 = '';
      let isbn10 = '';
      const key = `ISBN:${isbn}`;
      const jBook = res.data[key];
      if (jBook) {
        book.coverimage = [];
        book.title = jBook.title;
        book.subtitle = jBook.subtitle;
        // ToDo: Get only year
        book.published_year = jBook.publish_date;
        book.content_path = [jBook.url || ''];
        if (jBook.authors) book.author = jBook.authors.map(a => a.name);
        if (jBook.publishers) book.publisher = jBook.publishers.map(a => a.name);
        if (jBook.cover && jBook.cover.large) book.coverimage.push(jBook.cover.large);
        if (jBook.cover && jBook.cover.medium) book.coverimage.push(jBook.cover.medium);
        if (jBook.cover && jBook.cover.small) book.coverimage.push(jBook.cover.small);
        if (jBook.identifiers && jBook.identifiers.isbn_13) [isbn13] = jBook.identifiers.isbn_13;
        if (jBook.identifiers && jBook.identifiers.isbn_10) [isbn10] = jBook.identifiers.isbn_10;
        book.isbn_13 = isbn13;
        book.isbn_10 = isbn10;
      }
      resolve(book);
    }).catch(err => reject(err));
  });
}
